import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {errs} from 'payble-shared';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import Input from 'react-phone-number-input/input';
import {useNavigate} from 'react-router-dom';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useCurrentUser} from '../../../lib/auth';
import {Label, Switch, toast} from 'payble-ui';
import {useAPIMutation} from 'lib/api';
import {z} from 'zod';
import {AuthMethodTypeSchema, zPhoneNumber} from 'payble-api-client/schemas';
import {ContactAuthMethodPicker} from './components/ContactAuthMethodPicker';

const FORM_INITIAL_VALUES = {
  givenName: '',
  familyName: '',
  mobileNumber: '',
  email: '',
  sendIntroduction: false,
};

const ValidateContactSchema = z
  .object({
    givenName: z.string().min(1, 'Given name is equired'),
    familyName: z.string().min(1, 'Family name is required'),
    email: z.union([z.literal(''), z.string().email()]),
    mobileNumber: z.union([z.literal(''), zPhoneNumber]),
    region: z.enum(['AU', 'NZ']),
    authMethod: AuthMethodTypeSchema,
  })
  .superRefine((data, ctx) => {
    if (data.authMethod === 'mobile') {
      if (!data.mobileNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Mobile number is required. ',
          path: ['mobileNumber'],
        });
      }
      if (data.region === 'NZ' && !data.email) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Email is required. ',
          path: ['email'],
        });
      }
    } else if (data.authMethod === 'email') {
      if (!data.email) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Email is required. ',
          path: ['email'],
        });
      }
    }
  });

type AddContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
};

export const AddContactForm: React.FC<AddContactFormProps> = ({
  disclosure: {isOpen, onClose},
}) => {
  const navigate = useNavigate();
  const [existingContactId, setExistingContactId] = useState<string>();
  const {appConfig} = useCurrentUser();
  const {mutateAsync: createContact} = useAPIMutation('createContact', {
    query: {
      onSuccess: e => {
        if (e?.contactId) {
          navigate(`/audience/contact/${e.contactId}`);
        }
        onClose();
      },
      onError: e => {
        if (e instanceof errs.ContactAlreadyExists) {
          setExistingContactId(e.context.contactId);
        }
        toast({
          title: 'Failed to create contact',
          description: e.message,
          variant: 'destructive',
        });
      },
    },
  });
  const [authMethod, setAuthMethod] = useState<'mobile' | 'email'>(
    appConfig.authMethod[0] ?? 'mobile'
  );

  useEffect(() => {
    setExistingContactId('');
  }, [authMethod]);

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={{
          ...FORM_INITIAL_VALUES,
          region: appConfig.region,
          authMethod,
        }}
        validate={values => {
          try {
            ValidateContactSchema.parse(values);
          } catch (error) {
            if (error instanceof z.ZodError) {
              return error.formErrors.fieldErrors;
            }
          }
        }}
        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);
          await createContact({
            givenName: values.givenName,
            familyName: values.familyName,
            mobileNumber:
              values.mobileNumber === '' ? undefined : values.mobileNumber,
            sendIntroduction: values.sendIntroduction,
            email: values.email === '' ? undefined : values.email,
            authMethod,
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Contact"
                description="Add a contact to your audience."
              />
              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                {appConfig.authMethod.length > 1 && (
                  <ContactAuthMethodPicker
                    billerAuthMethods={appConfig.authMethod}
                    setAuthMethod={value => {
                      setAuthMethod(value);
                      setFieldValue('authMethod', value);
                    }}
                    selectedAuthMethod={authMethod}
                  />
                )}
                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="given-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Given Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="givenName"
                      id="given-name"
                      placeholder="Given Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.givenName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.givenName && touched.givenName && (
                      <FormErrorMessage message={errors.givenName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="family-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="familyName"
                      id="family-name"
                      placeholder="Family Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.familyName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.familyName && touched.familyName && (
                      <FormErrorMessage message={errors.familyName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div className="flex items-center gap-2">
                    <label
                      htmlFor="email"
                      className="text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      E-mail
                    </label>
                    {appConfig.region === 'AU' && authMethod === 'mobile' && (
                      <p className="text-sm text-gray-500 sm:mt-1.5">
                        (Optional)
                      </p>
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.email && touched.email && (
                      <FormErrorMessage message={errors.email} />
                    )}
                    {existingContactId && authMethod === 'email' && (
                      <div className="mt-1">
                        <a
                          href={`/audience/contact/${existingContactId}`}
                          className="font-semibold text-blue-600 font-sm hover:text-blue-500"
                        >
                          View existing contact.
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="mobile-number"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Mobile Number
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <Input
                      onChange={e => {
                        setFieldValue('mobileNumber', e);
                      }}
                      onBlur={handleBlur}
                      value={values.mobileNumber}
                      defaultCountry={appConfig.region}
                      type="tel"
                      name="mobileNumber"
                      id="mobile-number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="0400 000 000"
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <FormErrorMessage message={errors.mobileNumber} />
                    )}
                    {existingContactId && authMethod === 'mobile' && (
                      <div className="mt-1">
                        <a
                          href={`/audience/contact/${existingContactId}`}
                          className="font-semibold text-blue-600 font-sm hover:text-blue-500"
                        >
                          View existing contact.
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 px-6 mt-2">
                <Switch
                  id="send-welcome-sms"
                  onCheckedChange={checked =>
                    setFieldValue('sendIntroduction', checked)
                  }
                />
                <Label htmlFor="send-welcome-sms">
                  Send welcome {authMethod === 'mobile' ? 'SMS' : 'email'}
                </Label>
              </div>
            </div>
            <FormSubmission
              isSubmitting={isSubmitting}
              onCancel={onClose}
              submissionButtonText="Add Contact"
              submissionDisabled={Object.keys(errors).length > 0}
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
