import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PaymentMethodType, useAddPaymentMethodMutation} from 'lib/graphql/API';
import {auth} from '../../../lib';
import {BillerConfig, err} from 'payble-shared';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {BlockedSidebar} from '../components/BlockedSidebar';
import {getBillerGateway} from 'payble-shared/src/biller-config/handlers';
import {BillerAppConfig} from 'payble-shared/src/app-config/BillerAppConfig';
import {PaymentField} from 'features/payments/components/PaymentField';

function billerSupportsDirectDebit(
  billerConfig: BillerConfig,
  appConfig: BillerAppConfig
) {
  const gateway = getBillerGateway({
    billerConfig,
    paymentMethodType:
      appConfig.region === 'NZ' ? 'nz_direct_debit' : 'direct_debit',
  });

  return !err(gateway);
}

type AddContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  contactId: string;
};

export const AddPaymentMethodForm: React.FC<AddContactFormProps> = ({
  disclosure: {isOpen, onClose},
  contactId,
}) => {
  const navigate = useNavigate();
  const {appConfig, billerConfig} = auth.useCurrentUser();
  const supportsDirectDebit = billerSupportsDirectDebit(
    billerConfig,
    appConfig
  );
  const [addPaymentMethod] = useAddPaymentMethodMutation();

  if (!supportsDirectDebit) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Method"
        headerDescription="Add payment method to contact."
        headline="Missing direct debit gateway"
        text="Please contact us if you would like to enable direct debit."
        icon="BuildingLibraryIcon"
      />
    );
  }

  return (
    <PaymentField
      title="Add Payment Method"
      description="Add payment method to contact."
      onClose={onClose}
      isOpen={isOpen}
      submitButtonLabel="Add Payment Method"
      onSubmit={async (values, paymentMethodType) => {
        const result = await addPaymentMethod({
          variables: {
            input: {
              contactId,
              bank:
                paymentMethodType === PaymentMethodType.DirectDebit
                  ? {
                      bsb: values.bsbNumber,
                      accountName: values.accountName,
                      accountNumber: values.accountNumber,
                    }
                  : undefined,
              nzBank:
                paymentMethodType === PaymentMethodType.NzDirectDebit
                  ? {
                      accountName: values.accountName,
                      accountNumber: values.accountNumber,
                    }
                  : undefined,
              paymentMethodType,
            },
          },
        });

        const returnedContactId = result?.data?.addPaymentMethod.contact.id;

        if (returnedContactId) {
          navigate(`/audience/contact/${returnedContactId}/payment-methods`);
          onClose();
        }

        return result;
      }}
    />
  );
};
