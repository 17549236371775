import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {useSendAdminRequestedNotificationMutation} from 'lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {SelectMode, Form} from './components/SelectMode';
import {auth} from 'lib/index';
import {BillerConfig} from 'payble-shared';
import {
  MODES_ACCOUNT_VALIDATION,
  MODES_REQUIRE_PLAN,
  MODES_SUGGESTED_AMOUNT,
} from './components/SelectMode';

const modes = (billerConfig: BillerConfig) => [
  {
    name: 'Update payment method',
    id: 'updatePaymentMethod' as const,
    description: 'Send a notification with link to update payment method',
  },
  {
    name: 'Send link to create plan',
    id: 'createPlan' as const,
    description: 'Send a notification with link to create a plan',
  },
  {
    name: 'Send link to make payment',
    id: 'oneOffPayment' as const,
    description: 'Send a notification with link to make a payment',
  },
  ...(billerConfig.hasRequestPlan
    ? [
        {
          name: 'Send plan request invite',
          id: 'planRequestInvite' as const,
          description:
            'Send a notification with a link to plan requests, where a plan can be proposed for you to review',
        },
      ]
    : []),
  {
    name: 'Send link to edit profile',
    id: 'editProfile' as const,
    description: 'Send a notification with link to edit profile',
  },
  {
    name: 'Send link to cancel plan',
    id: 'cancelPlan' as const,
    description: 'Send a notification with link to cancel a plan',
  },
];

const FORM_INITIAL_VALUES: Form = {
  mode: undefined,
  planId: '',
  suggestedPlanSetup: false,
  instalmentFrequency: undefined,
  instalmentAmount: undefined,
  accountId: '',
  type: '',
};

type SendNotificationFormProps = {
  contactId: string;
  disclosure: ReturnType<typeof useDisclosure>;
};

export const SendNotificationForm: React.FC<SendNotificationFormProps> = ({
  contactId,
  disclosure: {isOpen, onClose},
}) => {
  const [preview, setPreview] = useState<string[]>();
  const [sendNotification] = useSendAdminRequestedNotificationMutation();
  const {billerConfig} = auth.useCurrentUser();
  const notificationOptions = modes(billerConfig);

  useEffect(() => {
    if (isOpen) {
      setPreview([]);
    }
  }, [isOpen]);

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validateOnChange={false}
        validate={values => {
          let errors = {};

          if (
            values.mode &&
            MODES_ACCOUNT_VALIDATION.includes(values.mode) &&
            !values.accountId
          ) {
            errors = {
              ...errors,
              accountId: 'Account is required',
            };
          }

          if (
            values.mode &&
            MODES_REQUIRE_PLAN.includes(values.mode) &&
            !values.planId
          ) {
            errors = {
              ...errors,
              planId: 'Plan is required',
            };
          }

          if (
            values.mode &&
            MODES_SUGGESTED_AMOUNT.includes(values.mode) &&
            values.suggestedPlanSetup &&
            !values.instalmentAmount
          ) {
            errors = {
              ...errors,
              instalmentAmount: 'Instalment amount is required',
            };
          }

          if (
            values.mode &&
            MODES_SUGGESTED_AMOUNT.includes(values.mode) &&
            values.suggestedPlanSetup &&
            !values.instalmentFrequency
          ) {
            errors = {
              ...errors,
              instalmentFrequency: 'Instalment frequency is required',
            };
          }
          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          if (!values.mode || !preview?.length) {
            return;
          }
          setSubmitting(true);

          await sendNotification({
            variables: {
              input: {
                contactId,
                type: values.mode,
                dryRun: false,
                accountId: values.accountId,
                planId: values.planId,
                instalmentAmount: values.instalmentAmount,
                instalmentFrequency: values.instalmentFrequency,
              },
            },
          });

          setSubmitting(false);

          onClose();
        }}
      >
        {({values, handleSubmit, isSubmitting, setFieldValue, errors}) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Send Notification"
                description="Send a notification to your contact."
              />
              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="flex-shrink-0 px-4 py-5 border-t border-gray-200 sm:px-6">
                  <SelectMode
                    selectedMode={notificationOptions.find(
                      mode => mode.id === values.mode
                    )}
                    contactId={contactId}
                    modes={notificationOptions}
                    showPreview
                    onPreviewUpdated={setPreview}
                    setMode={mode => {
                      setFieldValue('mode', mode.id, true);
                      setFieldValue('accountId', values.accountId ?? '', false);
                      setFieldValue('planId', '', false);
                    }}
                  />
                </div>
              </div>
            </div>

            <FormSubmission
              isSubmitting={isSubmitting || !preview?.length}
              onCancel={onClose}
              submissionButtonText="Send Notification"
              submissionDisabled={Object.keys(errors).length > 0}
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
