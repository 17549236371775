import {z} from 'zod';
import {
  filteredContactResponse,
  contactSourceSchema,
  filterContactsSchema,
  zNumberString,
  AuthMethodTypeSchema,
  zPhoneNumber,
} from '../schemas';
import {defineHTTPEndpoint} from '../utils';

export const createContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /createContact',
  requestSchema: z.object({
    givenName: z.string(),
    familyName: z.string(),
    email: z.string().email().optional(),
    mobileNumber: z.string().trim().optional(),
    sendIntroduction: z.union([z.null(), z.boolean()]),
    source: contactSourceSchema.optional(),
    authMethod: AuthMethodTypeSchema,
  }),
  responseSchema: z.object({contactId: z.string()}),
});

export const updateContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /updateContact',
  requestSchema: z.object({
    givenName: z.string(),
    familyName: z.string(),
    email: z.string().email().optional(),
    mobile: zPhoneNumber.optional(),
    source: contactSourceSchema.optional(),
    contactId: z.string(),
  }),
  responseSchema: null,
});

export const archiveContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /archiveContact',
  requestSchema: z.object({
    contactId: z.string(),
    archivedBy: z.string(),
    reason: z.string(),
  }),
  responseSchema: null,
});

export const restoreContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /restoreContact',
  requestSchema: z.object({
    contactId: z.string(),
    reason: z.string(),
  }),
  responseSchema: null,
});

export const searchContacts = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /searchContacts',
  queryKey: data => [
    'contacts-search',
    data?.page,
    data?.limit,
    data?.search,
    data?.filter,
  ],
  requestSchema: z.object({
    search: z.string().optional(),
    limit: zNumberString.optional(),
    page: zNumberString.optional(),
    filter: filterContactsSchema.optional(),
  }),
  responseSchema: z.object({
    rows: z.array(filteredContactResponse),
    total: z.number().int().min(0),
  }),
});
