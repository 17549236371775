import {Paginator} from 'components/atoms/Paginator';
import {PaymentMethodItem} from 'components/organisms/PaymentMethodItem';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {
  BanknotesIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {useCurrentUser} from 'lib/auth';
import {PaymentMethod} from 'lib/graphql/API';
import {APIOutput} from 'payble-api-client';
import {
  AbsoluteDate,
  capitalize,
  formatToDollars,
  toDateTime,
} from 'payble-shared';
import {DomainError} from 'payble-shared/src/errs';
import {DataTable} from 'payble-ui';
import NumberFormat from 'react-number-format';
import {
  filterPaymentsSchema,
  paymentStatusSchema,
} from 'payble-api-client/schemas/payment';
import {PaymentTableSkeleton} from './PaymentTableSkeleton';

export const PaymentDataTable: React.FC<{
  result?: APIOutput<'biller', 'searchPayments'>;
  goToPage?: (p: number) => void;
  page?: number;
  error: DomainError | null;
  loading?: boolean;
  onFilterChanged?: (filters?: any) => void;
  emptyComponent?: React.ReactNode;
}> = ({
  result,
  loading = false,
  error,
  page,
  onFilterChanged,
  goToPage,
  emptyComponent = (
    <div className="text-center py-8">
      <BanknotesIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No payments yet
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Your payments will display here.
      </p>
    </div>
  ),
}) => {
  const {billerConfig} = useCurrentUser();

  return (
    <DataTable
      rowKey="paymentId"
      onFilterChanged={onFilterChanged}
      loading={loading}
      loadingComponent={<PaymentTableSkeleton rows={4} />}
      emptyComponent={emptyComponent}
      error={error}
      columns={[
        {
          key: 'account',
          header: 'Account',
          render: (_, row) => (
            <div className="whitespace-nowrap text-sm text-gray-500">
              {row.account ? (
                <div key={row.account.id} className="py-2">
                  <div className="text-gray-900 font-medium">
                    {row.account.externalId} (
                    {billerConfig.getAccountTypeTitle(row.account.type)})
                  </div>
                  <div className="text-gray-500 truncate w-96">
                    {row.account.description}
                  </div>
                  <div className="text-gray-500">
                    <NumberFormat
                      value={formatToDollars(row.account.amountOwing)}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ),
        },
        {
          key: 'payment_method',
          header: 'Method',
          render: (_, row) => {
            // Temporary fix for the type mismatch between the API client and the old graphql
            const gqlTypeMap = {
              card: 'card',
              direct_debit: 'directDebit',
              nz_direct_debit: 'nzDirectDebit',
            };
            return (
              <div className="whitespace-nowrap text-sm text-gray-500">
                <PaymentMethodItem
                  paymentMethod={
                    {
                      ...row.paymentMethod,
                      type: gqlTypeMap[row.paymentMethod.type],
                      ...(row.paymentMethod.nzBank
                        ? {
                            nzBank: {
                              ...row.paymentMethod.nzBank,
                              accountNumber:
                                row.paymentMethod.nzBank.accountNumber.toJSON(),
                            },
                          }
                        : {}),
                    } as PaymentMethod
                  }
                />
              </div>
            );
          },
        },
        {
          key: 'amount',
          header: 'Amount / Type',
          render: (_, row) => (
            <div className="whitespace-nowrap text-sm text-gray-500">
              {row.mode === 'pay-in-full' && (
                <p className="flex">Paid in full</p>
              )}
              {row.mode === 'pay-amount' && (
                <p className="flex">Paid one-off</p>
              )}
              {row.mode === 'pay-instalment' && (
                <p className="flex">Pay instalment</p>
              )}
              {row.mode === 'pay-instalment-plan-remainder' && (
                <p className="flex">Pay remainder</p>
              )}
              <NumberFormat
                value={formatToDollars(row.amount)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
          ),
        },
        {
          key: 'status',
          header: 'Status',
          filters: {
            schema: filterPaymentsSchema.pick({status: true}),
            form: [
              {
                type: 'checkboxGroup',
                name: 'status',
                label: 'Status',
                options: Object.values(paymentStatusSchema.enum).map(value => ({
                  label: capitalize(value),
                  value,
                })),
              },
            ],
          },
          render: (_, row) => (
            <div className="whitespace-nowrap">
              {row.status === 'processing' && (
                <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Processing
                </span>
              )}
              {row.status === 'succeeded' && (
                <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  Paid
                </span>
              )}
              {row.status === 'failed' && (
                <TooltipMessage
                  tooltipText={row.failedReason?.replaceAll('_', ' ')}
                  tooltipBgColorAndHue="gray-50"
                  tooltipTextColorAndHue="red-800"
                  showTooltip={row.failedReason !== ''}
                >
                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Failed
                    {row.failedReason !== '' && (
                      <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-red-800 relative top-0.5" />
                    )}
                  </span>
                </TooltipMessage>
              )}
            </div>
          ),
        },
        {
          key: 'dates',
          header: 'Dates',
          filters: {
            schema: filterPaymentsSchema.pick({
              succeededAt: true,
              failedAt: true,
              processedAt: true,
              settlementDate: true,
            }),
            form: [
              {
                type: 'dateRange',
                name: 'succeededAt',
                label: 'Succeeded at',
                maxDate: AbsoluteDate.today({billerConfig}),
              },
              {
                type: 'dateRange',
                name: 'failedAt',
                label: 'Failed at',
                maxDate: AbsoluteDate.today({billerConfig}),
              },
              {
                type: 'dateRange',
                name: 'processedAt',
                label: 'Processed at',
                maxDate: AbsoluteDate.today({billerConfig}),
              },
              {
                type: 'dateRange',
                name: 'settlementDate',
                label: 'Settlement date',
                maxDate: AbsoluteDate.today({billerConfig}),
              },
            ],
          },
          render: (_, row) => (
            <div className="whitespace-nowrap">
              {row.paymentMethod?.type === 'card' ? (
                <div>
                  {row.status === 'succeeded' && (
                    <>
                      <div className="text-gray-500 text-xs font-semibold mt-2">
                        Succeeded At
                      </div>
                      <div className="text-sm">
                        {!!row.succeededAt &&
                          toDateTime(row.succeededAt, {
                            billerConfig,
                          }).toFormat('dd MMM yyyy')}
                      </div>
                    </>
                  )}
                  {row.status === 'failed' && (
                    <>
                      <div className="text-gray-500 text-xs font-semibold mt-2">
                        Failed At
                      </div>
                      <div className="text-sm">
                        {!!row.failedAt &&
                          toDateTime(row.failedAt, {
                            billerConfig,
                          }).toFormat('dd MMM yyyy')}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div className="text-gray-500 text-xs font-semibold mt-2">
                    Processed At
                  </div>
                  <div className="text-sm">
                    {!!row.processedAt &&
                      toDateTime(row.processedAt, {
                        billerConfig,
                      }).toFormat('dd MMM yyyy')}
                  </div>

                  {row.status === 'succeeded' && (
                    <>
                      <div className="text-gray-500 text-xs font-semibold mt-2">
                        Succeeded At
                      </div>
                      <div className="text-sm">
                        {!!row.succeededAt &&
                          toDateTime(row.succeededAt, {
                            billerConfig,
                          }).toFormat('dd MMM yyyy')}
                      </div>
                    </>
                  )}
                  {row.status === 'failed' && (
                    <>
                      <div className="text-gray-500 text-xs font-semibold mt-2">
                        Failed At
                      </div>
                      <div className="text-sm">
                        {!!row.failedAt &&
                          toDateTime(row.failedAt, {
                            billerConfig,
                          }).toFormat('dd MMM yyyy')}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ),
        },
      ]}
      rows={result?.rows}
    >
      <Paginator
        loading={loading}
        rows={result?.rows?.length || 0}
        goToPage={goToPage}
        page={page}
        total={result?.total || 0}
      />
    </DataTable>
  );
};
