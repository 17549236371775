import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import 'payble-ui/src/main.css';
import App from './App';
import {PaybleUI} from 'payble-ui';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from './queryClient';
import {api} from 'lib/api';

Sentry.init({
  dsn: 'https://f650d498782844ccbbda7683211f7603@o1097373.ingest.sentry.io/6467259',
  integrations: [new Integrations.BrowserTracing()],
  environment: import.meta.env.VITE_STAGE as string,
  tracesSampler: __context => {
    if (window.location.hostname === 'localhost') {
      return 0;
    } else {
      return 1;
    }
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <PaybleUI api={api}>
        <App />
      </PaybleUI>
    </QueryClientProvider>
  </React.StrictMode>
);
