import {GetAccountQuery} from 'lib/graphql/API';
import {useSearch} from 'lib/hooks/useSearch';
import {useEffect, useState} from 'react';
import {Search} from 'components/atoms/Search';
import {camelCaseToSentance, formatMoney, toTitleCase} from 'payble-shared';
import {FormErrorMessage} from 'features/audience/components/FormErrorMessage';
import {useAPIQuery} from 'lib/api';
import {APIOutput} from 'payble-api-client';

const AccountSummary: React.FC<{
  accountData: APIOutput<'biller', 'lookupAccounts'>[number];
}> = ({accountData}) => {
  if (!accountData) {
    return null;
  }

  const {
    id,
    type,
    info: {description, amountOwing},
  } = accountData;

  const accountSummary = {
    externalId: id,
    description,
    accountType: toTitleCase(type),
    amountOwing: formatMoney(amountOwing),
  };

  return (
    <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
      <div>
        <h3 className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
          Account Summary
        </h3>
      </div>
      <div className="sm:col-span-2">
        {Object.entries(accountSummary).map(([key, value]) => (
          <div
            className="py-1 flex justify-between text-sm font-medium"
            key={key}
          >
            <dt className="text-gray-500">
              {toTitleCase(camelCaseToSentance(key))}
            </dt>
            <dd className="text-gray-900">{value}</dd>
          </div>
        ))}
      </div>
    </div>
  );
};

type SelectedValue = {
  id: string;
  name: string;
  type: string;
  info: APIOutput<'biller', 'lookupAccounts'>[number]['info'];
};

export const SearchAccount = ({
  onDataChanged,
  onSearchError,
  errorMessage,
}: {
  onDataChanged: (v?: GetAccountQuery) => void;
  onSearchError: (v?: Error) => void;
  errorMessage?: string;
}) => {
  const [selected, setSelected] = useState<SelectedValue>();

  const lookup = useSearch();

  const selections = useAPIQuery('lookupAccounts', {
    data: lookup.value
      ? {
          lookup: lookup.value,
        }
      : undefined,
  });

  useEffect(() => {
    onSearchError(selections.error ?? undefined);
  }, [selections.error]);

  useEffect(() => {
    onDataChanged({account: selected?.info} as GetAccountQuery);
  }, [selected?.info]);

  return (
    <>
      <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="link-account"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Link To Account
            </label>
          </div>
          <div className="sm:col-span-2">
            <Search
              onSearchInputChange={searchValue => {
                lookup.onChange(searchValue);
              }}
              onSearchSelected={async value => {
                const lookup = selections?.data?.find(
                  account =>
                    account.id === value.id && account.type === value.type
                );

                if (lookup) {
                  setSelected(lookup);
                }
              }}
              selectedValue={selected}
              loading={selections.isFetching}
              values={selections.data}
              id="search-account"
            />
            {errorMessage && <FormErrorMessage message={errorMessage} />}
          </div>
        </div>
      </div>
      {selected && <AccountSummary accountData={selected} />}
    </>
  );
};
