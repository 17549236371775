import {PopOverCtaBottomRow} from 'components/atoms/PopOverCtaBottomRow';
import {DirectDebitAccountNumberAUField} from 'components/organisms/DirectDebit/DirectDebitAccountNumberAUField';
import {DirectDebitAccountNumberNZField} from 'components/organisms/DirectDebit/DirectDebitAccountNumberNZField';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from 'features/audience/forms/components/FormHeader';
import {GraphQLError} from 'graphql';
import {PaymentMethodType} from 'lib/graphql/API';
import {auth} from 'lib/index';
import {
  zAUBankAccountNumber,
  zAUBsbNumber,
  zNZBankAccountNumber,
} from 'payble-api-client/schemas';
import {
  ACCOUNT_NAME_MAX_LENGTH,
  errs,
  NZ_FE_ACCOUNT_NAME_MAX_LENGTH,
} from 'payble-shared';
import {Button, Form} from 'payble-ui';
import {ReactNode} from 'react';
import {z} from 'zod';

const Fields = () => {
  const {appConfig} = auth.useCurrentUser();

  return (
    <>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="link-account"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Account name
          </label>
        </div>
        <div className="sm:col-span-2">
          <Form.Input
            size="sm"
            id="accountName"
            name="accountName"
            placeholder="Account name"
            maxLength={
              appConfig.region === 'NZ'
                ? NZ_FE_ACCOUNT_NAME_MAX_LENGTH
                : ACCOUNT_NAME_MAX_LENGTH
            }
          />
        </div>
      </div>
      {appConfig.region === 'NZ' ? (
        <DirectDebitAccountNumberNZField />
      ) : (
        <DirectDebitAccountNumberAUField />
      )}
    </>
  );
};

const FORM_INITIAL_VALUES = {
  accountId: '',
  accountName: '',
  bsbNumber: '',
  accountNumber: '',
  accountNumberRemoteValidated: false,
  bsbNumberRemoteValidated: false,
};

export const PaymentField = ({
  defaultValues = FORM_INITIAL_VALUES,
  beforePaymentFields,
  submitButtonLabel,
  title,
  description,
  onSubmit,
  onClose,
  isOpen,
  additionalSchema = {},
}: {
  additionalSchema?: z.ZodRawShape;
  defaultValues?: typeof FORM_INITIAL_VALUES;
  beforePaymentFields?: ReactNode;
  title: string;
  description: string;
  submitButtonLabel: string;
  onSubmit: (
    values: typeof FORM_INITIAL_VALUES,
    paymentMethodType: PaymentMethodType
  ) => Promise<{errors?: readonly GraphQLError[]} | null>;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const {appConfig} = auth.useCurrentUser();

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Form
        schema={
          appConfig.region === 'NZ'
            ? z
                .object({
                  accountName: z.string(),
                  accountNumber: zNZBankAccountNumber,
                  accountNumberRemoteValidated: z.literal(true),
                })
                .extend(additionalSchema)
            : z
                .object({
                  accountName: z.string(),
                  accountNumber: zAUBankAccountNumber,
                  bsbNumber: zAUBsbNumber,
                  bsbNumberRemoteValidated: z.literal(true),
                })
                .extend(additionalSchema)
        }
        defaultValues={defaultValues}
        onSubmit={async (values, form) => {
          const paymentMethodType =
            appConfig.region === 'NZ'
              ? PaymentMethodType.NzDirectDebit
              : PaymentMethodType.DirectDebit;

          const result = await onSubmit(values, paymentMethodType);

          if (result?.errors) {
            form.setError('accountId', {
              message:
                errs
                  .fromGraphQL({
                    graphQLErrors: result.errors,
                  })
                  .first().message ?? 'Something went wrong.',
            });
          }
        }}
        className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
      >
        <div className="flex-1">
          <FormHeader
            setOpen={onClose}
            title={title}
            description={description}
          />

          <div className="flex flex-col sm:divide-y sm:divide-gray-200">
            {beforePaymentFields}
            <Fields />
          </div>
        </div>

        <PopOverCtaBottomRow>
          <Button variant="outline" size="sm" full={false} onClick={onClose}>
            Cancel
          </Button>
          <Form.SubmitButton size="sm" full={false}>
            {submitButtonLabel}
          </Form.SubmitButton>
        </PopOverCtaBottomRow>
      </Form>
    </PopOverSidebar>
  );
};
