import {FC, ReactNode} from 'react';
import {
  Root,
  Menu,
  Trigger,
  Portal,
  Content,
  Item,
  Sub,
  SubTrigger,
  SubContent,
} from '@radix-ui/react-menubar';
import {Icon} from './Icon';
import {cn} from '../lib/utils';

type MenuAction = {
  action: () => void;
};

type MenuItem = MenuAction & {
  label: string[];
};

type MenubarProps = {
  menu: MenuItem[];
  className?: string;
};

type MenuTree = {
  [key: string]: MenuTree | {action: () => void};
};

const buildNavigationTree = (navItems: MenuItem[]) => {
  const tree: MenuTree = {};

  navItems.forEach(item => {
    let currentLevel = tree;

    item.label.forEach((label, index) => {
      if (!currentLevel[label]) {
        currentLevel[label] =
          index === item.label.length - 1 ? {action: item.action} : {};
      }
      currentLevel = currentLevel[label] as MenuTree;
    });
  });

  return tree;
};

const MenuBaseOption: FC<{
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}> = ({children, onClick}) => (
  <Trigger
    onClick={onClick}
    className="flex cursor-default select-none items-center rounded-sm px-3 py-1 text-sm font-medium outline-none focus:bg-blue-500 focus:text-accent-foreground data-[state=open]:bg-blue-500 data-[state=open]:text-accent-foreground"
  >
    {children}
  </Trigger>
);

const SubMenuWrapper: FC<{children: ReactNode}> = ({children}) => (
  <Portal>
    <Content
      className="z-50 min-w-[12rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
      align="start"
      sideOffset={5}
      alignOffset={-3}
    >
      {children}
    </Content>
  </Portal>
);

const isMenuAction = (item: any): item is MenuAction => {
  return 'action' in item && typeof item.action === 'function';
};

const MenuRenderer: React.FC<{
  label: string;
  items: any;
  isRoot?: boolean;
}> = ({label, items, isRoot = false}) => {
  const isAction = isMenuAction(items);
  const triggerProps = isAction
    ? {
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          items.action();
        },
      }
    : {};

  const content = Object.entries(items).map(([key, value]) => (
    <MenuRenderer key={key} label={key} items={value} />
  ));

  return isAction ? (
    isRoot ? (
      <MenuBaseOption {...triggerProps}>{label}</MenuBaseOption>
    ) : (
      <Item
        {...triggerProps}
        className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-blue-500 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
        onSelect={e => e.preventDefault()}
      >
        {label}
      </Item>
    )
  ) : isRoot ? (
    <MenuBaseOption {...triggerProps}>
      {label}{' '}
      <Icon
        name="chevronDown"
        className="text-gray-300 data-[state=open]:text-white"
      />
    </MenuBaseOption>
  ) : (
    <Sub>
      <SubTrigger className="flex justify-between cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-blue-500 focus:text-accent-foreground data-[state=open]:bg-blue-500 data-[state=open]:text-accent-foreground">
        {label}{' '}
        <Icon
          name="chevronRight"
          className="text-gray-300 data-[state=open]:text-white"
        />
      </SubTrigger>
      <Portal>
        <SubContent
          className="z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          alignOffset={-5}
        >
          {content}
        </SubContent>
      </Portal>
    </Sub>
  );
};

export const Menubar: FC<MenubarProps> = ({menu, className}) => {
  return (
    <Root
      className={cn(
        'flex h-9 items-center space-x-1 rounded-md bg-background p-1',
        className
      )}
    >
      {Object.entries(buildNavigationTree(menu)).map(([key, items]) => (
        <Menu key={key}>
          <MenuRenderer label={key} items={items} isRoot />
          {!isMenuAction(items) && (
            <SubMenuWrapper>
              {Object.entries(items).map(([key, value]) => (
                <MenuRenderer key={key} label={key} items={value} />
              ))}
            </SubMenuWrapper>
          )}
        </Menu>
      ))}
    </Root>
  );
};
