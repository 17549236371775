import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {DirectDebitAccountNumberInputNZ} from 'payble-app-shared';
import {MiniSpinner, useFormContext} from 'payble-ui';
import {useDirectDebitRemoteValidationQuery} from './useDirectDebitRemoteValidationQuery';

const AccountNumberCheck = ({
  isFetching,
  data,
}: {
  isFetching: boolean;
  data?: {name: string} | null;
}) => {
  const {formState} = useFormContext();

  if (isFetching) {
    return <MiniSpinner />;
  }

  if (formState.errors?.accountNumber) {
    return (
      <div className="flex items-center gap-1">
        <XMarkIcon className="w-4 h-4 text-red-500" />
        <span className="text-sm text-gray-400">
          {formState.errors.accountNumber.message?.toString()}
        </span>
      </div>
    );
  }

  if (data) {
    return (
      <div className="flex items-center gap-1">
        <CheckIcon className="w-4 h-4 text-green-500" />
        <span className="text-sm text-gray-400">{data.name}</span>
      </div>
    );
  }

  return null;
};

export const DirectDebitAccountNumberNZField = () => {
  const {setValue, clearErrors, trigger} = useFormContext();

  const lookup = useDirectDebitRemoteValidationQuery({
    fieldName: 'accountNumber',
    remoteValidatedFieldName: 'accountNumberRemoteValidated',
    region: 'nz',
  });

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
          Account number
        </label>
      </div>
      <div className="sm:col-span-2">
        <DirectDebitAccountNumberInputNZ
          onChange={async value => {
            clearErrors(['accountNumber']);
            setValue('accountNumber', value.trim());
            setValue('accountNumberRemoteValidated', false);
            trigger(['accountNumber', 'accountNumberRemoteValidated']);
          }}
        />
        <div className="absolute !mt-1">
          <AccountNumberCheck
            isFetching={lookup.isFetching}
            data={lookup.data}
          />
        </div>
      </div>
    </div>
  );
};
