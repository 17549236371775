import {useFormContext} from 'payble-ui';
import {SearchAccount} from './SearchAccount';

export const SearchAccountField = () => {
  const {setValue, setError, clearErrors, formState, trigger} =
    useFormContext();

  return (
    <SearchAccount
      onDataChanged={data => {
        if (data?.account?.id) {
          clearErrors('accountId');
          setValue('accountId', data?.account?.id, {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setError('accountId', {message: 'Account required'});
        }

        trigger('accountId');
      }}
      onSearchError={error => {
        setError('accountId', {message: error?.message});
      }}
      errorMessage={
        formState.errors.accountId && formState.touchedFields.accountId
          ? formState.errors.accountId.message?.toString()
          : undefined
      }
    />
  );
};
