import {isPaybleStaff} from 'components/OnlyStaff';
import {Action, useExecuteAction} from 'features/navbar/useExecuteAction';
import {useCurrentUser} from 'lib/auth';
import {
  isBillerCreatedAutoAvailableForBiller,
  isBillerCreatedFlexibleFixedPlanAvailableForBiller,
  isBillerCreatedPayEveryXPlanAvailableForBiller,
} from 'lib/featureAvailable';
import {BillerConfig, safeEvaluate} from 'payble-shared';
import {Menubar} from 'payble-ui';

const NAVIGATION: {
  label: string[];
  action: Action;
  staffOnly?: boolean;
  if?: string;
  isAvailableForBiller?: (billerConfig: BillerConfig) => boolean;
}[] = [
  {
    label: ['Manage contact', 'Edit'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'edit-contact',
    },
  },
  {
    label: ['Manage contact', 'Archive'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'archive-contact',
    },
    if: '!contact.archivedAt',
  },
  {
    label: ['Manage contact', 'Unarchive'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'unarchive-contact',
    },
    if: 'contact.archivedAt',
  },
  {
    label: ['Plan', 'Enable autopay'],
    isAvailableForBiller: isBillerCreatedAutoAvailableForBiller,
    action: {
      type: 'open-drawer',
      openDrawerId: 'enable-autopay',
    },
  },
  {
    label: ['Plan', 'Enable flexible'],
    isAvailableForBiller: isBillerCreatedPayEveryXPlanAvailableForBiller,
    action: {
      type: 'open-drawer',
      openDrawerId: 'enable-flexible',
    },
  },
  {
    label: ['Plan', 'View payment plans'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/instalment-plans',
    },
  },
  {
    label: ['Plan', 'Add custom instalment plan'],
    isAvailableForBiller: isBillerCreatedFlexibleFixedPlanAvailableForBiller,
    action: {
      type: 'open-drawer',
      openDrawerId: 'add-instalment-plan',
    },
  },
  {
    label: ['Payment', 'View payments'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/payments',
    },
  },
  {
    label: ['Payment', 'View payment methods'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/payment-methods',
    },
  },
  {
    label: ['Payment', 'Add payment method'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'add-payment-method',
    },
  },
  {
    label: ['Account', 'View accounts'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/accounts',
    },
  },
  {
    label: ['Notification', 'View notifications'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/notifications',
    },
  },
  {
    label: ['Notification', 'Manage'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'manage-notification',
    },
  },
  {
    label: ['Notification', 'Send'],
    action: {
      type: 'open-drawer',
      openDrawerId: 'send-notification',
    },
  },
  {
    label: ['Request'],
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/requests',
    },
  },
  {
    label: ['Staff only', 'Analytics'],
    staffOnly: true,
    action: {
      type: 'navigate',
      path: '/audience/contact/:contactId/analytics',
    },
  },
];

export const ContactActionMenu = (options?: {context: Record<string, any>}) => {
  const execute = useExecuteAction(options?.context);
  const currentUser = useCurrentUser();

  const wonderful_nav_202503 = window.localStorage.getItem(
    'wonderful_nav_202503'
  );

  return (
    <div
      className={
        ['1', '2'].includes(wonderful_nav_202503 ?? '')
          ? `shadow-sm overflow-hidden rounded-md bg-white px-3 ${
              window.localStorage.getItem('wonderful_nav_fancy')
                ? 'border-2 border-blue-300 rounded-full'
                : ''
            }`
          : 'shadow-sm'
      }
    >
      <Menubar
        className="bg-white px-3"
        menu={NAVIGATION.filter(n => {
          if (n.isAvailableForBiller?.(currentUser.billerConfig) === false) {
            return false;
          }

          return !n.staffOnly || isPaybleStaff(currentUser);
        })
          .filter(n => !n.if || !!safeEvaluate(n.if, options?.context))
          .map(nav => ({
            ...nav,
            action: () => execute(nav.action),
          }))}
      />
    </div>
  );
};
