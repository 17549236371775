import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {
  paymentMethodResponse,
  zAbsoluteDate,
  zISODate,
  zNumberString,
} from '../schemas';
import {filterPaymentsSchema, paymentStatusSchema} from '../schemas/payment';

const zModes = z.enum([
  'pay-in-full',
  'pay-amount',
  'pay-instalment',
  'pay-instalment-plan-remainder',
]);

export const searchPayments = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /searchPayments',
  queryKey: data => ['search-payments', data],
  requestSchema: z.object({
    search: z.string().optional(),
    limit: zNumberString.optional(),
    page: zNumberString.optional(),
    modes: z.array(zModes).optional(),
    filter: filterPaymentsSchema.optional(),
  }),
  responseSchema: z.object({
    rows: z.array(
      z.object({
        accountId: z.string(),
        account: z.object({
          id: z.string(),
          type: z.string(),
          externalId: z.string(),
          description: z.string(),
          amountOwing: z.number(),
          status: z.string(),
        }),
        paymentMethodId: z.string(),
        paymentMethod: paymentMethodResponse,
        paymentId: z.string(),
        version: z.number(),
        mode: zModes,
        currency: z.string(),
        amount: z.number(),
        surchargeAmount: z.number(),
        reference: z.string().nullable(),
        instalmentPlanId: z.string().nullable(),
        instalmentId: z.string().nullable(),
        testMode: z.boolean(),
        gateway: z.string(),
        gatewayTransactionId: z.string().nullable(),
        gatewayResponse: z.unknown().nullable(),
        settlementDate: zAbsoluteDate.nullable(),
        refundStatus: paymentStatusSchema.nullable(),
        billerId: z.string(),
        isInteractive: z.boolean(),
        failedReason: z.string().nullable(),
        status: paymentStatusSchema,
        createdAt: zISODate,
        updatedAt: zISODate,
        processedAt: zISODate.nullable(),
        succeededAt: zISODate.nullable(),
        failedAt: zISODate.nullable(),
        refundedAt: zISODate.nullable(),
      })
    ),
    total: z.number().int().min(0),
  }),
});
