import {FormConfigField} from 'payble-shared';
import {create} from 'zustand';
import {ZodSchema} from 'zod';

interface CmdkState {
  currentForm?: {
    title?: string;
    schema?: ZodSchema;
    withoutButton?: boolean;
    fields: FormConfigField[];
    onSubmit: (values: Record<string, any>) => void;
  };
}

const getInitialState = (): CmdkState => ({
  currentForm: undefined,
});

export const useCmdkState = create<CmdkState>(getInitialState);

export const setCmdkCurrentForm = (currentForm?: CmdkState['currentForm']) =>
  useCmdkState.setState(() => ({
    currentForm,
  }));

export const removeCmdkCurrentForm = () =>
  useCmdkState.setState(() => ({
    currentForm: undefined,
  }));
