import React from 'react';

import {PaymentMethodType, useCreatePayerMutation} from 'lib/graphql/API';
import {useNavigate} from 'react-router-dom';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {PaymentField} from 'features/payments/components/PaymentField';
import {SearchAccountField} from 'components/organisms/SearchAccountField';
import {z} from 'zod';

type AddPayerFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
};

export const AddPayerForm: React.FC<AddPayerFormProps> = ({
  disclosure: {isOpen, onClose},
}) => {
  const [createPayer] = useCreatePayerMutation();
  const navigate = useNavigate();

  return (
    <PaymentField
      title="Add Payer"
      description="Add a payer to your audience."
      onClose={onClose}
      isOpen={isOpen}
      submitButtonLabel="Add Payer"
      beforePaymentFields={<SearchAccountField />}
      additionalSchema={{
        accountId: z.string(),
      }}
      onSubmit={async (values, paymentMethodType) => {
        const result = await createPayer({
          variables: {
            input: {
              accountId: values.accountId,
              bank:
                paymentMethodType === PaymentMethodType.DirectDebit
                  ? {
                      bsb: values.bsbNumber,
                      accountName: values.accountName,
                      accountNumber: values.accountNumber,
                    }
                  : undefined,
              nzBank:
                paymentMethodType === PaymentMethodType.NzDirectDebit
                  ? {
                      accountName: values.accountName,
                      accountNumber: values.accountNumber,
                    }
                  : undefined,
              paymentMethodType,
            },
          },
        });

        const payerId = result?.data?.createPayer.id;

        if (payerId) {
          navigate(`/audience/payer/${payerId}`);
        }

        return result;
      }}
    />
  );
};
