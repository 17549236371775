import {APIOutput} from 'payble-api-client';
import {classNames} from 'payble-app-shared/src/lib/styles';
import {Form, Icon, Loading, Text, useFormContext} from 'payble-ui';
import {useDirectDebitRemoteValidationQuery} from './useDirectDebitRemoteValidationQuery';

const BsbNumberCheck = ({
  bank,
  isBankLoading,
}: {
  bank?: APIOutput<'biller', 'getBankByCode'> | null;
  isBankLoading?: boolean;
}) => {
  const context = useFormContext();

  if (context.formState.errors.bsbNumber?.type) return null;

  if (isBankLoading) {
    return <Loading variant="mini" />;
  }

  if (bank) {
    return (
      <div className="flex items-center gap-1" id="valid-bsb">
        <Icon name="check" variant="fill" color="green" />
        <Text size="xs">{bank.name}</Text>
        {bank.icon && <img src={bank.icon} className="w-4 ml-1" />}
      </div>
    );
  }

  return null;
};

export const DirectDebitAccountNumberAUField = () => {
  const context = useFormContext();
  const lookup = useDirectDebitRemoteValidationQuery({
    fieldName: 'bsbNumber',
    remoteValidatedFieldName: 'bsbNumberRemoteValidated',
    region: 'au',
  });

  return (
    <>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
            Bsb number
          </label>
        </div>
        <div className="sm:col-span-2">
          <Form.InputNumberFormat
            format="###-###"
            placeholder="000-000"
            name="bsbNumber"
            id="bsb"
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            )}
            size="sm"
            allowLeadingZeros
            onValueChange={() => {
              context.setValue('bsbNumberRemoteValidated', false);
              context.clearErrors(['bsbNumber']);
              context.trigger(['bsbNumber', 'bsbNumberRemoteValidated']);
            }}
          />
          <div className="absolute">
            <BsbNumberCheck
              isBankLoading={lookup.isFetching}
              bank={lookup.data}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
            Account number
          </label>
        </div>
        <div className="sm:col-span-2">
          <Form.InputNumberFormat
            format="#########"
            placeholder="000000000"
            name="accountNumber"
            id="accountNumber"
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            )}
            autoComplete={'off'}
            size="sm"
            setValueAs="string"
            allowLeadingZeros
            mask={' '}
            onValueChange={() => {
              context.clearErrors(['accountNumber']);
              context.trigger('accountNumber');
            }}
          />
        </div>
      </div>
    </>
  );
};
