import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  Account,
  AuthMethod,
  Contact as ContactFragment,
  useGetContactQuery,
} from 'lib/graphql/API';
import React, {Fragment, useMemo} from 'react';
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {err} from 'payble-shared';
import {Loading} from 'components/atoms/Loading';
import {
  ShieldCheckIcon,
  BanknotesIcon,
  ChatBubbleLeftIcon,
  UserIcon,
  ChevronDownIcon,
  BellIcon,
  ArchiveBoxXMarkIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import {Breadcrumbs} from 'components/atoms/Breadcrumbs';
import classNames from 'classnames';
import {Tabs} from 'components/atoms/Tabs';
import {ContactAccounts} from './ContactAccounts';
import {ContactPaymentMethods} from './ContactPaymentMethods';
import {InstalmentPlans} from './InstalmentPlans';
import {Payments} from './Payments';
import {Menu, Transition} from '@headlessui/react';
import {AddPaymentMethodForm} from '../forms/AddPaymentMethodForm';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {AddInstalmentPlanForm} from '../forms/AddInstalmentPlanForm';
import {auth} from 'lib/index';
import {AddAutoPayForm} from '../forms/AddAutoPayForm';
import {SendNotificationForm} from '../forms/SendNotificationForm';
import {Notifications} from './Notifications';
import {Requests} from './Requests';
import {CreatePlansMenuOptions} from '../components/CreatePlansMenuOptions';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';
import {EditContactForm} from '../forms/EditContactForm';
import {AddFlexiblePlanForm} from '../forms/AddFlexiblePlanForm';
import {NotificationSettingTableForm} from '../components/NotificationSettingTableForm';
import {Analytics} from './Analytics';
import {ArchiveContact} from '../forms/ArchiveContact';
import {ArchiveStatusPill} from '../components/ArchiveStatusPill';
import {UnarchiveContact} from '../forms/UnarchiveContact';
import {ContactActionMenu} from '../components/ContactActionMenu';
import {useExecuteLocationStateAction} from 'lib/hooks/useExecuteLocationStateAction';

const getContactFriendlyName = (contact: ContactFragment) => {
  const friendlyName = `${contact.givenName} ${contact.familyName}`.trim();
  if (friendlyName !== '') {
    return friendlyName;
  }

  if (contact.email) {
    return contact.email;
  }

  if (contact.mobile) {
    return contact.mobile;
  }

  return friendlyName;
};

const getVariables = (lookup: string) => {
  if (lookup.startsWith('mobile:')) {
    // Do a query based on the mobile
    return {
      mobile: lookup.replace('mobile:', ''),
    };
  }

  if (lookup.startsWith('email:')) {
    // Do a query based on the email
    return {
      email: lookup.replace('email:', ''),
    };
  }

  if (lookup.startsWith('externalId:')) {
    // Do a query based on the externalId
    return {
      externalId: lookup.replace('externalId:', ''),
    };
  }
  return {
    id: lookup,
  };
};

const useVariables = (lookup?: string) => {
  const variables = useMemo(() => lookup && getVariables(lookup), [lookup]);
  return variables;
};

const useLocationTitle = () => {
  const location = useLocation();
  const lastSegment = location.pathname.split('/').at(-1);

  switch (lastSegment) {
    case 'instalment-plans':
      return 'Payment Plans';
    case 'payments':
      return 'Payments';
    case 'payment-methods':
      return 'Payment Methods';
    case 'accounts':
      return 'Accounts';
    case 'notifications':
      return 'Notifications';
    case 'analytics':
      return 'Analytics';
    case 'requests':
      return 'Requests';
    default:
      return 'Payment Plans';
  }
};

export const Contact: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const title = useLocationTitle();
  const {lookup} = useParams<{lookup: string}>();
  const {email, appConfig, billerConfig} = auth.useCurrentUser();

  const addPaymentMethodDisclosure = useDisclosure();
  const addInstalmentPlanFormDisclosure = useDisclosure();
  const enableAutoPayDisclosure = useDisclosure();
  const enableFlexibleDisclosure = useDisclosure();
  const sendNotificationDisclosure = useDisclosure();
  const editContactDisclosure = useDisclosure();
  const manageNotificationsDisclosure = useDisclosure();
  const archiveContactDisclosure = useDisclosure();
  const unarchiveContactDisclosure = useDisclosure();
  const isPaybleStaff = email.endsWith('payble.com.au');

  const variables = useVariables(lookup);
  if (!variables) return <ErrorMessage message="No lookup provided" />;
  if (err(variables)) return <ErrorMessage message={variables.message} />;

  const {
    data,
    loading,
    error,
    refetch: refetchContact,
  } = useGetContactQuery({
    variables,
  });

  const wonderful_nav_202503 = window.localStorage.getItem(
    'wonderful_nav_202503'
  );

  useExecuteLocationStateAction('openDrawerId', {
    'edit-contact': editContactDisclosure.onOpen,
    'archive-contact': archiveContactDisclosure.onOpen,
    'unarchive-contact': unarchiveContactDisclosure.onOpen,
    'add-instalment-plan': addInstalmentPlanFormDisclosure.onOpen,
    'enable-autopay': enableAutoPayDisclosure.onOpen,
    'enable-flexible': enableFlexibleDisclosure.onOpen,
    'send-notification': sendNotificationDisclosure.onOpen,
    'manage-notification': manageNotificationsDisclosure.onOpen,
    'add-payment-method': addPaymentMethodDisclosure.onOpen,
  });

  return (
    <>
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage message={error.message} /> : null}
      {!loading && !error && data && data.contact ? (
        <div>
          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {wonderful_nav_202503 === '3' ? (
                <ContactActionMenu
                  context={{contactId: data.contact.id, contact: data.contact}}
                />
              ) : (
                <div
                  className={`flex flex-wrap ${wonderful_nav_202503 === '4' ? 'flex-col justify-start' : 'justify-between items-center'} gap-4 `}
                >
                  <Breadcrumbs
                    pages={[
                      {name: 'Audience', href: '/audience'},
                      {
                        name: getContactFriendlyName(
                          data.contact as ContactFragment
                        ),
                        href: `/audience/contact/${data.contact.id}`,
                        current: true,
                      },
                    ]}
                  />

                  {wonderful_nav_202503 === '1' ||
                  wonderful_nav_202503 === '4' ? (
                    <ContactActionMenu
                      context={{
                        contactId: data.contact.id,
                        contact: data.contact,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div className="bg-white shadow sm:rounded-lg mt-8">
                <div className="flex justify-between">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex gap-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Contact Information
                      </h3>
                      {data.contact.archivedAt && <ArchiveStatusPill />}
                    </div>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Details and contact information.
                    </p>
                  </div>
                  {!wonderful_nav_202503 && (
                    <Menu
                      as="div"
                      className="relative top-5 right-6 inline-block text-right"
                    >
                      <div className="">
                        <Menu.Button
                          className="inline-flex items-center gap-2 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          id="contact-menu"
                        >
                          Actions & options
                          <ChevronDownIcon
                            className="size-4"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {!data.contact.archivedAt ? (
                              <>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      onClick={() =>
                                        editContactDisclosure.onOpen()
                                      }
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-500',
                                        'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                      )}
                                    >
                                      Edit contact
                                      <UserIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      onClick={() =>
                                        sendNotificationDisclosure.onOpen()
                                      }
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-500',
                                        'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                      )}
                                    >
                                      Send notification
                                      <ChatBubbleLeftIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      onClick={() =>
                                        addPaymentMethodDisclosure.onOpen()
                                      }
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-500',
                                        'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                      )}
                                    >
                                      Add Payment Method
                                      <BanknotesIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </button>
                                  )}
                                </Menu.Item>
                                <CreatePlansMenuOptions
                                  billerConfig={billerConfig}
                                  onOpenInstalmentPlanForm={() =>
                                    addInstalmentPlanFormDisclosure.onOpen()
                                  }
                                  onOpenEnableAutoPay={() =>
                                    enableAutoPayDisclosure.onOpen()
                                  }
                                  onOpenFlexiblePaymentPlan={() => {
                                    enableFlexibleDisclosure.onOpen();
                                  }}
                                />
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      onClick={() =>
                                        manageNotificationsDisclosure.onOpen()
                                      }
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-500',
                                        'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                      )}
                                    >
                                      Manage notifications
                                      <BellIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      onClick={() =>
                                        archiveContactDisclosure.onOpen()
                                      }
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-500',
                                        'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                      )}
                                    >
                                      Archive contact
                                      <ArchiveBoxXMarkIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </button>
                                  )}
                                </Menu.Item>
                              </>
                            ) : (
                              <Menu.Item>
                                {({active}) => (
                                  <button
                                    onClick={() =>
                                      unarchiveContactDisclosure.onOpen()
                                    }
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-500',
                                      'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                    )}
                                  >
                                    Unarchive contact
                                    <ArrowPathIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            )}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                  <ArchiveContact
                    contactId={data.contact.id}
                    disclosure={archiveContactDisclosure}
                    refetch={refetchContact}
                  />

                  <UnarchiveContact
                    contactId={data.contact.id}
                    disclosure={unarchiveContactDisclosure}
                    refetch={refetchContact}
                  />
                  <EditContactForm
                    contactId={data.contact.id}
                    givenName={data.contact.givenName}
                    familyName={data.contact.familyName}
                    email={data.contact.email ?? undefined}
                    mobile={data.contact.mobile ?? undefined}
                    authMethod={data.contact.authMethod}
                    disclosure={editContactDisclosure}
                    refetch={refetchContact}
                  />
                  <AddPaymentMethodForm
                    contactId={data.contact.id}
                    disclosure={addPaymentMethodDisclosure}
                  />
                  <AddInstalmentPlanForm
                    contactId={data.contact.id}
                    disclosure={addInstalmentPlanFormDisclosure}
                    paymentMethods={data.contact.paymentMethods}
                  />
                  <AddAutoPayForm
                    contactId={data.contact.id}
                    paymentMethods={data.contact.paymentMethods}
                    disclosure={enableAutoPayDisclosure}
                  />
                  <AddFlexiblePlanForm
                    disclosure={enableFlexibleDisclosure}
                    paymentMethods={data.contact.paymentMethods}
                    contactId={data.contact.id}
                  />
                  <SendNotificationForm
                    contactId={data.contact.id}
                    disclosure={sendNotificationDisclosure}
                  />

                  <NotificationSettingTableForm
                    contactId={data.contact.id}
                    disclosure={manageNotificationsDisclosure}
                  />
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Given name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.contact.givenName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Family name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.contact.familyName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 truncate ">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            data.contact.authMethod === 'email'
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <a href={`mailto:${data.contact.email}`} className="">
                          {data.contact.email ?? 'No email linked'}
                        </a>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Mobile
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            data.contact.authMethod === AuthMethod.Mobile
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        {data.contact.mobile ? (
                          <PhoneNumberFormat
                            value={data.contact.mobile}
                            region={appConfig.region}
                          />
                        ) : (
                          'No mobile linked'
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {wonderful_nav_202503 ? (
                <div className="flex gap-4 justify-between items-center">
                  <h3 className="text-3xl leading-6 font-bold text-gray-900">
                    {title}
                  </h3>

                  {wonderful_nav_202503 === '2' && (
                    <ContactActionMenu
                      context={{
                        contactId: data.contact.id,
                        contact: data.contact,
                      }}
                    />
                  )}
                </div>
              ) : (
                <Tabs
                  tabs={[
                    {
                      name: 'Payment Plans',
                      onClick: () => {
                        navigate(
                          `/audience/contact/${lookup}/instalment-plans`,
                          {
                            state: {lookup},
                          }
                        );
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/instalment-plans',
                          location.pathname
                        ) !== null ||
                        matchPath(
                          '/audience/contact/:lookup',
                          location.pathname
                        ) !== null,
                    },
                    {
                      name: 'Payments',
                      onClick: () => {
                        navigate(`/audience/contact/${lookup}/payments`, {
                          state: {lookup},
                        });
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/payments',
                          location.pathname
                        ) !== null,
                    },
                    {
                      name: 'Payment Methods',
                      onClick: () => {
                        navigate(
                          `/audience/contact/${lookup}/payment-methods`,
                          {
                            state: {lookup},
                          }
                        );
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/payment-methods',
                          location.pathname
                        ) !== null,
                    },
                    {
                      name: 'Accounts',
                      onClick: () => {
                        navigate(`/audience/contact/${lookup}/accounts`, {
                          state: {lookup},
                        });
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/accounts',
                          location.pathname
                        ) !== null,
                    },
                    {
                      name: 'Notifications',
                      onClick: () => {
                        navigate(`/audience/contact/${lookup}/notifications`, {
                          state: {lookup},
                        });
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/notifications',
                          location.pathname
                        ) !== null,
                    },
                    ...(isPaybleStaff
                      ? [
                          {
                            name: 'Analytics',
                            onClick: () => {
                              navigate(
                                `/audience/contact/${lookup}/analytics`,
                                {
                                  state: {lookup},
                                }
                              );
                            },
                            current:
                              matchPath(
                                '/audience/contact/:lookup/analytics',
                                location.pathname
                              ) !== null,
                          },
                        ]
                      : []),
                    {
                      name: 'Requests',
                      onClick: () => {
                        navigate(`/audience/contact/${lookup}/requests`, {
                          state: {lookup},
                        });
                      },
                      current:
                        matchPath(
                          '/audience/contact/:lookup/requests',
                          location.pathname
                        ) !== null,
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div className="mt-8 pb-8">
            <Routes>
              <Route
                path=""
                element={<InstalmentPlans contactId={data.contact.id} />}
              />
              <Route
                path="accounts"
                element={
                  <ContactAccounts
                    refetch={refetchContact}
                    contact={data.contact}
                    accounts={data.contact.accounts as Account[]}
                  />
                }
              />
              <Route
                path="payment-methods"
                element={
                  <ContactPaymentMethods
                    paymentMethods={data.contact.paymentMethods}
                    refetch={refetchContact}
                  />
                }
              />
              <Route
                path="instalment-plans"
                element={<InstalmentPlans contactId={data.contact.id} />}
              />
              <Route
                path="payments"
                element={<Payments contactId={data.contact.id} />}
              />
              <Route
                path="notifications"
                element={<Notifications contactId={data.contact.id} />}
              />
              <Route
                path="analytics"
                element={<Analytics contactId={data.contact.id} />}
              />
              <Route
                path="requests"
                element={<Requests contactId={data.contact.id} />}
              />
            </Routes>
          </div>
        </div>
      ) : null}
    </>
  );
};
