import {ReactNode, useEffect, useState} from 'react';
import {Loading} from './Loading';
import {Popover, PopoverContent, PopoverTrigger, PopoverClose} from './Popover';
import {isDeepEmpty, getDotNotationValue, deepClearEmpty} from 'payble-shared';
import {Form} from './Form';
import {Button, ButtonProps} from './Button';
import {FormBuilder} from './FormBuilder';
import {FormConfigField} from 'payble-shared';
import {AnyZodObject} from 'zod';
import {Icon} from './Icon';
import {useFormContext} from '../hooks/useFormContext';

type Row = {[key in string]: unknown};

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

type Column<T> = {
  key: string;
  header: ReactNode;
  render?: (value: unknown, row: T) => ReactNode;
  visible?: boolean;
  filters?: {
    schema: AnyZodObject;
    form: FormConfigField[];
  };
};

type FilterData = {[key in string]: any};

type TableProps<T extends Row> = {
  loading?: boolean;
  error?: {message: string} | null;
  columns?: Column<T>[];
  rows?: T[];
  // rowKey is a key of T that must be string
  rowKey: StringKeys<T>;
  initialFiltersData?: FilterData;
  onFilterChanged?: (filters: FilterData) => void;
  children?: ReactNode;
  loadingComponent?: ReactNode;
  emptyComponent?: ReactNode;
};

function isColumnFilterActive(
  filtersData: FilterData,
  form?: FormConfigField[]
) {
  return (
    form?.some(field => {
      const value = getDotNotationValue(filtersData, field.name);
      return !isDeepEmpty(value);
    }) ?? false
  );
}

export const FilterIcon = ({
  filtersData,
  form,
}: {
  filtersData: FilterData;
  form?: FormConfigField[];
}) => {
  const isActive = isColumnFilterActive(filtersData, form);

  return (
    <Icon
      name="filter"
      className={`cursor-pointer ${isActive ? 'text-primary' : ''}`}
      variant={isActive ? 'fill' : 'line'}
      size={16}
    />
  );
};

export const ClearFiltersButton = ({
  columnFormDef,
  onCleared,
  ...props
}: ButtonProps & {
  columnFormDef: FormConfigField[];
  onCleared: (data: FilterData) => void;
}) => {
  const {resetField, getValues} = useFormContext();
  return (
    <Button
      {...props}
      size="sm"
      className="flex-1"
      variant="outline"
      onClick={e => {
        columnFormDef?.forEach(field => {
          resetField(field.name, {defaultValue: '', keepDirty: false});
        });
        onCleared(getValues());
        props.onClick?.(e);
      }}
    >
      Clear all
    </Button>
  );
};

export const DataTable = <T extends Row>({
  loading,
  error,
  columns,
  rowKey,
  rows,
  initialFiltersData,
  onFilterChanged,
  children,
  loadingComponent,
  emptyComponent,
}: TableProps<T>) => {
  const [filtersData, setFiltersData] = useState(initialFiltersData ?? {});

  useEffect(() => {
    onFilterChanged?.(filtersData);
  }, [filtersData]);

  function updateFiltersData(data: any) {
    setFiltersData(
      deepClearEmpty({
        ...filtersData,
        ...data,
      })
    );
  }

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {columns
              ?.filter(column => column.visible !== false)
              ?.map(column => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:px-6"
                >
                  <div className="flex items-center gap-2">
                    {column.header}
                    {column.filters && (
                      <Popover>
                        <PopoverTrigger>
                          <FilterIcon
                            filtersData={filtersData}
                            form={column.filters.form}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <FormBuilder
                            id={`table-filter-${column.key}`}
                            defaultValues={filtersData}
                            schema={column.filters.schema}
                            fields={column.filters.form}
                            size="sm"
                            onSubmit={data => {
                              updateFiltersData(data);
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <PopoverClose className="flex-1" asChild>
                                <ClearFiltersButton
                                  onCleared={updateFiltersData}
                                  columnFormDef={column.filters?.form}
                                />
                              </PopoverClose>
                              <PopoverClose className="flex-1" asChild>
                                <Form.SubmitButton size="sm">
                                  Apply
                                </Form.SubmitButton>
                              </PopoverClose>
                            </div>
                          </FormBuilder>
                        </PopoverContent>
                      </Popover>
                    )}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {loading ? (
            loadingComponent ? (
              loadingComponent
            ) : (
              <tr>
                <td colSpan={columns?.length ?? 1} className="py-10">
                  <Loading />
                </td>
              </tr>
            )
          ) : null}
          {error ? (
            <tr>
              <td colSpan={columns?.length ?? 1} className="pb-10">
                {error.message}
              </td>
            </tr>
          ) : !loading && !rows?.length ? (
            <tr>
              <td colSpan={columns?.length ?? 1} className="py-10">
                {emptyComponent ?? 'No data'}
              </td>
            </tr>
          ) : null}
          {!loading &&
            rows?.map(row => (
              <tr key={`${row[rowKey]}`}>
                {columns
                  ?.filter(column => column.visible !== false)
                  ?.map(column => (
                    <td
                      key={`${row[rowKey]}-${column.key}`}
                      className="py-4 pl-3 text-sm text-gray-500 whitespace-nowrap sm:px-6"
                    >
                      {column.render?.(row[column.key], row) ??
                        (row[column.key] as ReactNode) ??
                        null}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};
