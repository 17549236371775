import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {zAUBsbNumber, zNZBankAccountNumber} from '../schemas';

export const getBankByCode = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /getBankByCode',
  queryKey: e => ['bank-code', e?.code],
  requestSchema: z.discriminatedUnion('region', [
    z.object({code: zAUBsbNumber, region: z.literal('au')}),
    z.object({code: zNZBankAccountNumber, region: z.literal('nz')}),
  ]),
  responseSchema: z
    .object({
      code: z.string(),
      name: z.string(),
      icon: z.string().optional(),
    })
    .nullable(),
});
