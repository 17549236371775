import {BillerConfig} from 'payble-shared';

function isBillerCreatedAutoAvailableForBiller(billerConfig: BillerConfig) {
  return (
    (billerConfig.billerPortalFeatureOverride?.AUTO ||
      billerConfig?.features?.AUTO) &&
    billerConfig.adminCreatedPlans.autoPay.enabled
  );
}

function isBillerCreatedPayEveryXPlanAvailableForBiller(
  billerConfig: BillerConfig
) {
  return (
    billerConfig?.features?.PAY_EVERY_X &&
    billerConfig.adminCreatedPlans.flexible.enabled
  );
}

function isBillerCreatedFlexibleFixedPlanAvailableForBiller(
  billerConfig: BillerConfig
) {
  return billerConfig.adminCreatedPlans.flexibleFixed.enabled;
}

export {
  isBillerCreatedAutoAvailableForBiller,
  isBillerCreatedPayEveryXPlanAvailableForBiller,
  isBillerCreatedFlexibleFixedPlanAvailableForBiller,
};
