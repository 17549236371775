import {useAPIQuery} from 'lib/api';
import {AUBsbNumber, NZBankAccountNumber} from 'payble-shared';
import {useFormContext} from 'payble-ui';
import {useEffect} from 'react';
import {useDebounce} from 'use-debounce';

const RegionCodeValueObjectsMap = {
  au: AUBsbNumber,
  nz: NZBankAccountNumber,
} as const;

type QueryData =
  | {region: 'au'; code: AUBsbNumber}
  | {region: 'nz'; code: NZBankAccountNumber}
  | undefined;

export const useDirectDebitRemoteValidationQuery = <
  T extends keyof typeof RegionCodeValueObjectsMap,
>({
  fieldName,
  remoteValidatedFieldName,
  region,
}: {
  fieldName: string;
  remoteValidatedFieldName: string;
  region: T;
}) => {
  const {setValue, setError, watch} = useFormContext();
  const accountNumber = watch(fieldName);

  const [debouncedInput] = useDebounce({accountNumber, region}, 500);
  const regionCodeValueObject = RegionCodeValueObjectsMap[region];

  if (!regionCodeValueObject) throw new Error(`Unsupported region ${region}`);

  const code = regionCodeValueObject.maybeFromJSON(
    debouncedInput.accountNumber
  );

  const data: QueryData = code
    ? region === 'nz'
      ? {region: 'nz', code: code as NZBankAccountNumber}
      : {region: 'au', code: code as AUBsbNumber}
    : undefined;

  const lookup = useAPIQuery('getBankByCode', {
    data,
  });

  useEffect(() => {
    if (lookup.isFetching) {
      setValue(remoteValidatedFieldName, false, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [lookup.isFetching]);

  useEffect(() => {
    if (lookup.isFetchedAfterMount) {
      if (lookup.data) {
        setValue(remoteValidatedFieldName, true, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        setError(fieldName, {message: 'Unknown bank branch code'});
      }
    }
  }, [lookup.isFetchedAfterMount, lookup.data]);

  useEffect(() => {
    if (lookup.error) {
      setError(fieldName, {message: lookup.error.message});
    }
  }, [lookup.error, fieldName]);

  return lookup;
};
