import React from 'react';
import {CheckIcon} from '@heroicons/react/20/solid';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';
import {Combobox} from '@headlessui/react';
import classNames from 'classnames';
import {MiniSpinner} from './Spinner';

type SearchValue = {
  id: string;
  name: string;
  type: string;
};
type SearchProps = {
  values?: SearchValue[];
  selectedValue?: {id: string; type: string};
  onSearchInputChange: (id: string) => void;
  onSearchSelected(searchValue: SearchValue): void;
  loading: boolean;
  id?: string;
};

function matchSelectedValue(
  value: SearchValue,
  selectedValue?: {id: string; type: string}
) {
  return value.id === selectedValue?.id && value.type === selectedValue?.type;
}

export const Search: React.FC<SearchProps> = ({
  values,
  selectedValue,
  onSearchInputChange,
  onSearchSelected,
  loading,
  id,
}) => {
  return (
    <Combobox
      as="div"
      value={
        values?.find(value => matchSelectedValue(value, selectedValue)) ?? null
      }
      onChange={value => {
        if (value) {
          onSearchSelected(value);
        }
      }}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          id={id}
          onChange={event => {
            onSearchInputChange(event.target.value);
          }}
          displayValue={() =>
            values?.find(value => matchSelectedValue(value, selectedValue))
              ?.name ?? ''
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {loading ? (
            <div className="-mt-1">
              <MiniSpinner />
            </div>
          ) : (
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </Combobox.Button>

        {!loading && values && values.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {values.map((value, i) => (
              <Combobox.Option
                key={value.id}
                id={`search-result-${i}`}
                value={value}
                className={({active}) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({active, selected}) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold'
                      )}
                    >
                      {value.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-blue-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
