import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';

export const removePaymentMethod = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'DELETE /removePaymentMethod',
  requestSchema: z.object({
    paymentMethodId: z.string(),
  }),
  responseSchema: z.object({
    status: z.string(),
    id: z.string(),
  }),
});
