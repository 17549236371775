import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {Account, Contact, Maybe} from 'lib/graphql/API';
import {toTitleCase} from 'payble-shared';
import React from 'react';

type AccountStandingProps = {
  account: Account;
  contact?: Maybe<Contact> | undefined;
};
export const AccountStanding: React.FC<AccountStandingProps> = ({
  account,
  contact,
}) => {
  const planCancellationReason =
    account.standing?.instalmentPlanCancelledReason;
  const showCancelTooltip =
    planCancellationReason && planCancellationReason !== '';

  const isPlanOwnedByContact =
    account.standing?.owner?.type === 'CONTACT' &&
    account.standing?.owner?.id === contact?.id;

  if (contact?.id && !isPlanOwnedByContact) {
    return <div key={account.id} className="py-4 h-20"></div>;
  }

  return (
    <div key={account.id} className="py-4 h-20">
      <TooltipMessage
        tooltipText={planCancellationReason}
        tooltipBgColorAndHue="gray-50"
        tooltipTextColorAndHue="orange-800"
        showTooltip={!!showCancelTooltip}
      >
        <span
          className={classNames(
            'inline-flex rounded-full px-4 py-2 text-xs font-semibold leading-5 ',
            isPositiveStatus(account.standing?.status)
              ? 'bg-green-100 text-green-800'
              : '',
            isNeutralStatus(account.standing?.status)
              ? 'bg-gray-100 text-gray-800'
              : '',
            isNegativeStatus(account.standing?.status)
              ? 'bg-orange-100 text-orange-800'
              : ''
          )}
        >
          {account.standing?.status
            ? toTitleCase(account.standing.status.replace(/_/g, ' '))
            : 'No plan active'}
          {showCancelTooltip && (
            <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-orange-800 relative top-0.5" />
          )}
        </span>
      </TooltipMessage>
    </div>
  );
};

function isPositiveStatus(status: string | null | undefined) {
  switch (status) {
    case 'succeeded':
    case 'completed':
    case 'on_track':
    case 'paid':
      return true;
  }
  return false;
}

function isNeutralStatus(status: string | null | undefined) {
  switch (status) {
    case undefined:
    case null:
    case 'processing':
    case 'pending':
      return true;
  }
  return false;
}

function isNegativeStatus(status: string | null | undefined) {
  switch (status) {
    case 'cancelled':
    case 'overdue':
    case 'failed':
      return true;
  }
  return false;
}
