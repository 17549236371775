import React, {useEffect} from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import {Skeleton} from 'features/navbar/Skeleton';
import {Audience} from 'features/audience/Audience';
import {Home} from 'features/home/Home';
import {Payments} from 'features/payments/Payments';
import {Login} from 'features/login/Login';
import {Account} from 'features/audience/pages/Account';
import {Contact} from 'features/audience/pages/Contact';
import {ImportsExports} from 'features/import-export/ImportExport';
import {Payer} from 'features/audience/pages/Payer';
import {useLoginFlow} from './lib/auth/useAuthState';
import {PlanRequests} from 'features/audience/pages/PlanRequests';
import {AuditLogs} from './features/audit/pages/AuditLogs';
import {EnvironmentsConfig} from './features/config/pages/EnvironmentsConfig';
import {ConfigLayout} from './features/config/pages/ConfigLayout';
import {EnvironmentConfigForm} from './features/config/pages/EnvironmentConfigForm';
import {AutoImportsConfigForm} from './features/config/pages/AutoImportsConfigForm';
import {AutoImportsConfig} from 'features/config/pages/AutoImportsConfig';
import {MaintenanceBanner} from 'features/audience/components/MaintenanceBanner';
import {Navbar as Navbar202503} from 'features/navbar/Navbar202503';
import {Navbar} from 'features/navbar/Navbar';
import {ReportingDashboardPage} from './features/analytics/ReportingDashboardPage';

export const Navigation: React.FC = () => {
  const loginFlow = useLoginFlow();
  const wonderful_nav_202503 = window.localStorage.getItem(
    'wonderful_nav_202503'
  );

  useEffect(() => {
    if (loginFlow.type === 'UNINITIALIZED') {
      loginFlow.initialize();
    }
  }, [loginFlow.type]);

  if (loginFlow.type === 'UNINITIALIZED') {
    return <Skeleton />;
  }

  if (loginFlow.type !== 'LOGGED_IN') {
    return <Login />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <MaintenanceBanner />
      <Router>
        {wonderful_nav_202503 ? <Navbar202503 /> : <Navbar />}
        <Routes>
          <Route path="/import-export" element={<ImportsExports />} />
          <Route path="/payments/*" element={<Payments />}></Route>
          <Route path="/plan-requests/*" element={<PlanRequests />} />
          <Route path="/audit/*" element={<AuditLogs />} />
          <Route path="/audience" element={<Audience />} />
          <Route path="/audience/account/:lookup/*" element={<Account />} />
          <Route path="/audience/contact/:lookup/*" element={<Contact />} />
          <Route path="/audience/payer/:lookup/*" element={<Payer />} />
          <Route
            path="/reports/dashboard/:reportingDashboardId"
            element={<ReportingDashboardPage />}
          />
          <Route path="/config" element={<ConfigLayout />}>
            <Route
              index
              element={<Navigate to="/config/environments/biller" replace />}
            />
            <Route
              path="environments/:configType"
              element={<EnvironmentsConfig />}
            >
              <Route path=":type" element={<EnvironmentConfigForm />} />
            </Route>
            <Route path="auto-imports">
              <Route index element={<AutoImportsConfig />} />
              <Route path="create" element={<AutoImportsConfigForm />} />
              <Route
                path="edit/:autoImportConfigId"
                element={<AutoImportsConfigForm />}
              />
            </Route>
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </Router>
    </div>
  );
};
