import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {ImportsTable} from './components/ImportsTable';
import {ExportsTable} from './components/ExportsTable';
import {useLocation} from 'react-router-dom';

type Mode = 'imports' | 'exports';

const useLocationState = () => {
  const location = useLocation();
  const locationState = location.state as {mode: Mode} | undefined;
  return locationState;
};

const useLocationMode = () => {
  const locationState = useLocationState();
  const state = useState<Mode>(locationState?.mode ?? 'imports');
  const [, setMode] = state;

  useEffect(() => {
    if (locationState?.mode) {
      setMode(locationState.mode);
    }
  }, [locationState?.mode]);

  return state;
};

export const ImportsExports: React.FC = () => {
  const [mode, setMode] = useLocationMode();

  return (
    <div className="py-10">
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div>
            <div className="relative border-b border-gray-200 sm:pb-0">
              <div className="md:flex md:items-center md:justify-between">
                <h3 className="text-3xl leading-6 font-bold text-gray-900">
                  Import/Export
                  <br />
                  <p className="flex mt-2 mb-2 text-sm text-gray-700 font-normal">
                    Import and export customer data to efficiently keep Payble
                    in sync with your ERP and other information systems.
                  </p>
                </h3>
              </div>
              <div className="mt-4">
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    <div
                      onClick={() => setMode('imports')}
                      className={classNames(
                        mode === 'imports'
                          ? 'border-payble-violet text-payble-violet'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                      )}
                      aria-current={mode === 'imports' ? 'page' : undefined}
                    >
                      Imports
                    </div>
                    <div
                      onClick={() => setMode('exports')}
                      className={classNames(
                        mode === 'exports'
                          ? 'border-payble-violet text-payble-violet'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                      )}
                      aria-current={mode === 'exports' ? 'page' : undefined}
                    >
                      Exports
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="flex justify-end py-2 min-w-full"></div>
                  {mode === 'imports' && <ImportsTable />}
                  {mode === 'exports' && <ExportsTable />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
