import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {
  accountSearchResponse,
  billerAccountResponse,
  filterAccountsSchema,
  okayResponse,
  zNumberString,
} from '../schemas';

export const contactRemove = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'DELETE /accountContactRemove',
  requestSchema: z.object({
    contactId: z.string().min(1).trim(),
    accountId: z.string().min(1).trim(),
    reason: z.string(),
  }),
  responseSchema: okayResponse,
});

export const searchAccounts = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /searchAccounts',
  queryKey: data => [
    'accounts-search',
    data?.page,
    data?.limit,
    data?.search,
    data?.filter,
  ],
  requestSchema: z.object({
    search: z.string().optional(),
    limit: zNumberString.optional(),
    page: zNumberString.optional(),
    filter: filterAccountsSchema.optional(),
  }),
  responseSchema: z.object({
    rows: z.array(accountSearchResponse),
    total: z.number().int().min(0),
  }),
});

export const lookupAccounts = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /lookupAccounts',
  queryKey: data => ['lookup-accounts', data?.lookup],
  requestSchema: z.object({
    lookup: z.string(),
  }),
  responseSchema: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
      name: z.string(),
      info: billerAccountResponse,
    })
  ),
});
