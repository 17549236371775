import React from 'react';
import {HomeIcon} from '@heroicons/react/20/solid';
import {useNavigate} from 'react-router-dom';

type Breadcrumb = {
  name: string;
  href: string;
  current?: boolean;
};

export type BreadcrumbsProps = {
  pages: Breadcrumb[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({pages}) => {
  const navigate = useNavigate();
  const wonderful_nav_202503 = window.localStorage.getItem(
    'wonderful_nav_202503'
  );

  return (
    <nav
      className={wonderful_nav_202503 ? 'flex h-[36px]' : 'flex'}
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className="bg-white rounded-md shadow px-6 flex space-x-4"
      >
        <li className="flex">
          <div className="flex items-center">
            <div
              onClick={() => navigate('/')}
              className="text-gray-400 hover:text-gray-500 cursor-pointer"
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </div>
        </li>
        {pages.map(page => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <a
                href={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
