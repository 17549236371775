import {useParams} from 'react-router-dom';
import {useAPIQuery} from '../../lib/api';
import {useCurrentUser} from '../../lib/auth';
import {Loading} from '../../components/atoms/Loading';
import {ErrorMessage} from '../../components/atoms/ErrorMessage';
import {iframeResizer} from 'iframe-resizer';
import {cn} from 'payble-ui';
import {useState} from 'react';

export const ReportingDashboardPage = () => {
  const {reportingDashboardId} = useParams();
  const {appConfig} = useCurrentUser();
  const [loaded, setLoaded] = useState<boolean>(false);

  const definition = appConfig.dashboards.find(
    d => d.reportingDashboardId === reportingDashboardId
  );
  if (!definition) {
    throw new Error(`No dashboard found with id ${reportingDashboardId}`);
  }

  const dash = useAPIQuery('getReportingDashboard', {
    data: {
      reportingDashboardId: definition.reportingDashboardId,
    },
  });

  if (dash.error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <ErrorMessage message={dash.error.message} />
        </div>
      </div>
    );
  }

  if (!dash.data) {
    return (
      <div className="mt-8">
        <Loading />
      </div>
    );
  }

  return (
    <div className="mh-screen">
      <div style={{maxWidth: '1048px'}} className="mx-auto p-8 sm:px-6 lg:px-8">
        <div className="relative border-b border-gray-200 sm:pb-0">
          <div className="md:flex  py-8 md:items-center md:justify-between">
            <h3 className="text-3xl leading-6 font-bold text-gray-900">
              {dash.data.displayName}
            </h3>
          </div>
        </div>
        <iframe
          src={
            dash.data.iframeURL +
            '#background=false&bordered=false&titled=false&downloads=true'
          }
          className={cn(
            'w-full overflow-hidden border-none',
            loaded ? 'opacity-100' : 'opacity-0'
          )}
          onLoad={function (this: HTMLElement) {
            setLoaded(true);
            iframeResizer({}, this);
          }}
        />
      </div>
    </div>
  );
};
