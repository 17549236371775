import React from 'react';
import {useAuthState} from '../lib/auth';

export const isPaybleStaff = (user?: {email?: string}) =>
  user?.email?.endsWith('payble.com.au');

export const OnlyStaff: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({children}) => {
  const {currentUser} = useAuthState();

  if (isPaybleStaff(currentUser)) {
    return <>{children}</>;
  }
  return null;
};
