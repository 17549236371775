import {BanknotesIcon} from '@heroicons/react/24/outline';
import {PaymentDataTable} from 'features/payments/components/PaymentDataTable';
import {useAPIQuery} from 'lib/api';
import {APIInput} from 'payble-api-client';
import React from 'react';

type PaymentsProps = {
  contactId?: string;
  accountId?: string;
};

export const Payments: React.FC<PaymentsProps> = ({contactId, accountId}) => {
  const [page, setPage] = React.useState(1);
  const [paymentFilter, setPaymentFilter] =
    React.useState<APIInput<'biller', 'searchPayments'>['filter']>();
  const searchPayments = useAPIQuery('searchPayments', {
    data: {
      filter: {...paymentFilter, accountId, contactId},
      page,
    },
  });

  const goToPage = (pageNum: number) => {
    setPage(pageNum);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <PaymentDataTable
        result={searchPayments.data}
        loading={searchPayments.isLoading}
        error={searchPayments.error}
        goToPage={goToPage}
        page={page}
        onFilterChanged={setPaymentFilter}
        emptyComponent={
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <BanknotesIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No payments
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                All payments made for this {accountId ? 'account' : 'contact'}{' '}
                will display here.
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
};
