import React, {Fragment, useEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';
import {auth} from '../../lib';
import {useLocalStorageSync} from 'lib/hooks/useLocalStorageSync';
import {OnlyStaff} from 'components/OnlyStaff';
import {Link} from 'react-router-dom';
import {NavCommand} from './NavCommand';
import {NavMenubar} from './NavMenubar';

export const Navbar: React.FC = () => {
  const loginFlow = auth.useLoginFlow();

  const signOut = () => {
    if (loginFlow.type === 'LOGGED_IN') {
      loginFlow.logout();
    }
  };

  const {billerSlug: slug} = auth.useCurrentUser();
  const [currentBiller, setcurrentBiller] = useState(slug);
  const [store, setStore] = useLocalStorageSync('biller', slug);

  useEffect(() => {
    setcurrentBiller(slug);
    setStore(slug);
  }, [slug]);

  useEffect(() => {
    if (store !== currentBiller) {
      window.location.reload();
    }
  }, [store]);

  return (
    <nav className="shadow-sm bg-white">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-12 items-center">
          <div className="flex items-center gap-4">
            <NavLink to={'/'}>
              <img
                className="hidden w-auto h-6 lg:block"
                src="/cms/payble-logo.png"
                alt="Payble"
              />
            </NavLink>

            <NavMenubar />
          </div>

          <div className="flex gap-4 items-center">
            <NavCommand />
            <img
              src={
                slug ? `/cms/biller/${slug}/logo.png` : '/cms/payble-logo.png'
              }
              className="grow-0 h-8"
            />
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-payble-coral">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                    <svg
                      className="w-full h-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <OnlyStaff>
                    <Menu.Item key="config">
                      <Link
                        to="/config"
                        className={classNames(
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200'
                        )}
                      >
                        Manage Config
                      </Link>
                    </Menu.Item>
                  </OnlyStaff>
                  <Menu.Item key="profile">
                    <div
                      onClick={signOut}
                      className={classNames(
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200'
                      )}
                    >
                      Sign out
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  );
};
