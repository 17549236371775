import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useExecuteLocationStateAction = (
  stateKey: string,
  actionMap: Record<string, () => void>
) => {
  const location = useLocation();
  const state = location.state as Record<string, unknown> | null;

  useEffect(() => {
    const stateKeyValue = state?.[stateKey];

    if (typeof stateKeyValue === 'string' && stateKeyValue in actionMap) {
      actionMap[stateKeyValue]();
    }
  }, [state]);

  return null;
};
