import React from 'react';
import {classNames} from 'lib/styles';
import {RadioGroup} from '@headlessui/react';

export const AUTH_METHODS: {
  id: 'mobile' | 'email';
  name: string;
  description: string;
}[] = [
  {
    id: 'mobile',
    name: 'Mobile login',
    description: 'Create a contact with mobile login method',
  },
  {
    id: 'email',
    name: 'Email login',
    description: 'Create a contact with email login method',
  },
];

type ContactAuthMethodPickerProps = {
  setAuthMethod: (authMethod: 'mobile' | 'email') => void;
  disabled?: boolean;
  selectedAuthMethod?: 'mobile' | 'email';
  billerAuthMethods: ('mobile' | 'email')[];
};

export const ContactAuthMethodPicker: React.FC<
  ContactAuthMethodPickerProps
> = ({setAuthMethod, disabled, selectedAuthMethod, billerAuthMethods}) => {
  const availableAuthMethods = AUTH_METHODS.filter(({id}) =>
    billerAuthMethods.includes(id)
  );

  return (
    <RadioGroup
      value={selectedAuthMethod}
      onChange={setAuthMethod}
      disabled={disabled}
    >
      <div className="flex flex-col px-4 py-4 sm:px-6 sm:py-6">
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900 sm:mb-2">
            Select contact login method
          </label>
        </div>
        {availableAuthMethods.map((method, index) => (
          <RadioGroup.Option
            key={method.id}
            value={method.id}
            className={({checked}) =>
              classNames(
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === AUTH_METHODS.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'z-9 border-blue-200 bg-blue-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none'
              )
            }
          >
            {({checked, active}) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-blue-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="flex flex-col gap-3 ml-3">
                  <div>
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? 'text-blue-900' : 'text-gray-900',
                        'block text-sm font-medium'
                      )}
                      id={`mode-${method.id}`}
                    >
                      {method.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? 'text-blue-700' : 'text-gray-500',
                        'block text-sm'
                      )}
                    >
                      {method.description}
                    </RadioGroup.Description>
                  </div>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
